import { Link } from "react-router-dom"
import { ModalContent } from "./ModalContent"
import { socket } from "../../components/Containers/MainContainer"
import { useState } from "react"


export const InfoModalContent = (props) => {
    const [code, setCode] = useState('0')
    return (
        <ModalContent 
        click={props.click}
        hideTitle={true}
        title={props.title} 
        className={`modal-content-${props.modalSize}`}
        content={
            <>
               <div className="modal-content-sized-container" >
               {props.modalSize=='small' ||  props.modalSize=='small-2' ||  props.modalSize=='small-3' ? (props.image=='' || props.image==undefined) ?'':  <div className="modal-content-sized-container-image"> 
                        <img src={props.image} alt="" />
                    </div>:''}
                    <div className="modal-content-sized-container-text">
                    {props.modalSize=="small-2" || props.modalSize=="small-3"?<div className="modal-content-sized-container-text-title" dangerouslySetInnerHTML={{ __html:props.title}}/>: <div className="modal-content-sized-container-text-title">{props.title}</div>}
                        {props.modalSize=="small-2" || props.modalSize=="small-3"?<div className="modal-content-sized-container-text-description" dangerouslySetInnerHTML={{ __html:props.description}}/>:<div className="modal-content-sized-container-text-description" >{handleDescription(props.description) }</div>}
                        </div>
                      {props.modalSize=="small-2" || props.modalSize=="small-3"?<div className={`modal-content-sized-container-buttons ${props.canClaim==false?'':'enabled'}`}>
                        {new Date().getDate()==13?
                            <div className="input-container" style={{marginTop:'20px'}} >
                                <input type="number" placeholder="Enter number" onChange={(e)=>setCode(e.target.value)} min={"1"} max={"500"} defaultValue={"0"}/>
                            </div>
                        :''}
                        <div className="modal-content-sized-container-buttons-button" onClick={()=>{
                          socket.emit('userAction', {action:'claimChristmas2024',
                          code:code
                          })
                          if(new Date().getDate()==7){
                            // if(props.canClaim==true){
                             window.open('https://play.google.com/store/apps/details?id=com.ml.freelitecoin','_blank' // <- This is what makes it open in a new window.
);
                            // }
                          }
                        }}>{new Date().getDate()==7?'Download':'Claim'}</div>
                    </div>:''}
                  {props.modalSize=='small'?  <div className="modal-content-sized-container-buttons">
                        <div className="modal-content-sized-container-buttons-button" onClick={props.click}>Close</div>
                    </div>:''}
                </div>
            </>
        }/>
    )
}
function handleDescription(description)  {

    return parseTextWithLinks(description)
}
const parseTextWithLinks = (text) => {
    // Regular expression to match <Link to='/path'>Link Text</Link>
    const linkRegex = /<Link to='(.*?)'>(.*?)<\/Link>/g;
  
    // Split the text by <Link> tags and replace with actual Link components
    const parts = [];
    let lastIndex = 0;
    let match;
  
    // Iterate through all the matches of <Link>
    while ((match = linkRegex.exec(text)) !== null) {
      // Add the text before the link
      if (match.index > lastIndex) {
        parts.push(text.slice(lastIndex, match.index));
      }
  
      // Add the actual <Link> component
      parts.push(
        <Link key={match.index} to={match[1]}>
          {match[2]}
        </Link>
      );
  
      // Update the lastIndex to the end of the current match
      lastIndex = linkRegex.lastIndex;
    }
  
    // Add any remaining text after the last link
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }
  
    return <>{parts}</>;
  };

  
import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { Link, Navigate, useParams } from "react-router-dom"
import { socket } from "../components/Containers/MainContainer"
import { Popover } from "@headlessui/react"
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"
import { FaArrowDown, FaArrowUp, FaRegQuestionCircle, FaStar, FaTextHeight, FaTicketAlt } from "react-icons/fa"
import { IoSearchSharp } from "react-icons/io5"
import { ChangeStateUrl, ChangeStateUrlPath, getDevicesSelected, numberWithCommas } from "../components/utils"
import queryString from "query-string"
import '../assets/style/OffersCont.css'
import { PromotedSmallOfferItem } from "../Component/PromotedSmallOffer/Item.PromotedSmallOffer"
import { PromotedOfferModalContent } from "./ModalContent/PromotedOffer.ModalContent"
import InfiniteScroll from "react-infinite-scroll-component"
import { set } from "nprogress"
import { Loader } from "../components/Loader"
import ProgressiveImage from "react-progressive-image-loading"

export const Christmas2024Container = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [christmasArray, setChristmasArray] = useState(new Array(24).fill({}))
    const [tickets, setTickets] = useState(-1)
    

   
    useEffect(() => {
        socket.emit('getChristmas2024', {})
        socket.on('EmitChristmas2024', (data) => {
            console.log(data)
            setChristmasArray(data.newChristmasArray)
            setTickets(data.tickets)
        })
    }, [])

    return (
       
       <div className="offers-container">
          <div className='cashout-header' style={{marginBottom:'-20px'}}>
                <div className='cashout-header-left'>
                <div className="cashout-title">🎄✨ Celebrate X-Mas with Daily Rewards! Christmas Event 2024 ✨🎄</div>
                <div className="cashout-description">This Christmas, PaidCash is making the season brighter with our exclusive 2024 Advent Calendar Event! From December 1st to December 24th, unlock a new surprise each day and earn festive rewards!</div>
                </div>
                <div className='cashout-header-right' style={{marginBottom:'20px'}}>
                    <div className='cashout-header-balance'>
                        <div className='cashout-header-balance-amount'>
                        <FaTicketAlt  className="ticket-design" style={{marginRight:'10px'}}/> 
                            <div className="header-balance-text">{tickets==-1?<Skeleton className='offer-featured-box' style={{ width: '10px', height: '10px' }} />:numberWithCommas(tickets)}</div>
                        </div>
                        <div className='cashout-header-balance-title'>Current Tickets</div>
                    </div>
                  
                </div>
            </div>
          
          
               <div className="box-event-container">

                    {christmasArray.map((item, i) => {
                          if (item.Prize == undefined) {
                            return <Skeleton className='offer-featured-box' style={{ width: '100px', height: '170px', marginRight:'15px', borderRadius: '8px' }} />
                        }else
                        return <div key={i} className='offer-featured-box'>
                            {console.log(new Date().getDate()>=(i+1), new Date().getDate(), (i+1))}
                           <div onClick={()=>{
                            if(new Date().getDate()==(i+1)){
                                window.dispatchEvent(new CustomEvent('showInfoModal', {detail: {title: `🎄✨ Christmas 2024 - Day ${(i+1)} ✨🎄<br/>Task - ${item.Condition}`, description: `<br/>Be rewarded ${item.Prize.toLowerCase()} for completing the task<br/><br/>`, type:'small-2', canClaim:item.canClaim==undefined?false:item.canClaim, image:`/assets/img/christmas2024/day${i+1}.png`}}))
                            }
                           }} className={`box-event ${new Date().getDate()==(i+1) || item.claimed==true?'':'blur-event-box'}`}>
                                {item.claimed==true?'':<img className="badge-img-event" src={`/assets/img/christmas2024/${new Date().getDate()<=(i+1)?'new-day':'missed-day'}.png`} alt="" />}
                                 <img className="badge-img-event-main"  src={`/assets/img/christmas2024/day${i+1}.png`} alt="" />
                            </div>
        </div>})}
        </div>
            </div>

    )
}
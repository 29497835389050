import { useEffect } from "react"
import { useState } from "react"
import Skeleton from "react-loading-skeleton"
import { USDWithCommas, emitSocket, getChatStatus, getSelectedWithdrawTab, getShownCurency, numberWithCommas, onSocket, setWithdrawTabStatus }  from "../components/utils"

import { FaDollarSign, FaTicketAlt, FaUsers } from "react-icons/fa"
import ProgressiveImage from "react-progressive-image-loading"
import { socket } from "../components/Containers/MainContainer.js"
import '../assets/style/Leaderboard.css'
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import UserIDProfileModalContainerDTImg from "../components/earn/UserIDProfileModalContainerDTImg.js"
export const Christmas2024Prize = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
    const [data, setData] = useState([])
    const [usersToday, setUsersToday] = useState(-1)
    const [userPositionToday, setUserPositionToday] = useState(-1)
    const [userEarnedToday, setUserEarnedToday] = useState(-1);
    const [timerMonthly, setTimerMonthly] = useState(0)
    const [updateTimerMonthly, setUpdateTimerMonthly] = useState(false)
    const [textTimer, setTextTimer] = useState('-1')
    const [userEarnings, setUserEarnings] = useState([])
    const [isOpen, setIsOpen] = useState(true)
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [claimedAmount, setClaimedAmount] = useState(0);
    const [toClaimAmount, setToClaimAmount] = useState(0);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    let networkTimeout = null;
    useEffect(() => {
        socket.emit('emitRankingDataChristmas2024')
        socket.on('rankingDataChristmas2024', (data) => {
            console.log(data)
            let users = data.ranking.map((x, i) => {
                return {
                    id: i,
                    ...x
                }
            })
            console.log(users)

            setData([...users])
            setTotalRecords(data.length)
            setLoading(false);

        })
        socket.emit('emitRankingDataDetailsChristmas2024')
        socket.on('rankingDataDetailsChristmas2024', (data) => {
            setUsersToday(data.usersEarnedToday)
            setUserPositionToday(data.userPositionToday)
            setUserEarnedToday(data.userEarnedToday);
           
        }   )
        
        socket.emit('emitTimeUntilTomorrwMSChristmas2024')
        socket.on('timeUntilTomorrwMSChristmas2024', (data) => {
            setTimerMonthly(data.rankingEnd)
            setUpdateTimerMonthly(updateTimerMonthly == true ? false : true);

        })

    }, [])
    useEffect(function () {

        setTimeout(function () {
            if(timerMonthly<=0){
                setTextTimer(`Ended`)
            }else{
            setTimerMonthly(timerMonthly - 1);
            if (timerMonthly > 0) {
                setUpdateTimerMonthly(updateTimerMonthly == true ? false : true);

            }
            let remainedSeconds = timerMonthly;
            let days = parseInt(timerMonthly / 60 / 60 / 24);
            remainedSeconds -= days * 24 * 60 * 60;
            let hours = parseInt(remainedSeconds / 60 / 60);
            remainedSeconds -= hours * 60 * 60;
            let minutes = parseInt(remainedSeconds / 60);
            remainedSeconds -= minutes * 60;
            let seconds = parseInt(remainedSeconds);
            setTextTimer(`${days}D ${hours}H ${minutes}M ${seconds}s`)
        }
        }, 1000 * 1)
    }, [updateTimerMonthly])
   
  

    useEffect(() => {
        loadLazyData();
    }, [lazyState]);

    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
      

    };

  
    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            userEarnings.getCustomers().then((data) => {
                setSelectAll(true);
                setSelectedCustomers(data.customers);
            });
        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    useEffect(() => {
        setIsOpen(true)

            ;
    }, [])
    const [tickets, setTickets] = useState(-1)
    

   
    useEffect(() => {
        socket.emit('getChristmas2024', {})
        socket.on('EmitChristmas2024', (data) => {
            setTickets(data.tickets)
        })
    }, [])
    return (
        <div className={`page-container`}>
            <div className="leaderboard-banner">
                <div className="leaderboard-banner-title">
                🎄✨ X-Mas Leaderboard 🎄✨
                </div>
                <div className="leaderboard-banner-description">
                    Ready to cash in on your coin collecting skills? This Christmas, we're giving away a total of $100, split among the top 3 earners on our Christmas leaderboard! The more tickets you earn, the bigger your share of the prize. Whether you’re a casual collector or a coin-crushing champion, every coin brings you closer to real cash!
                    <br/>
                    Don't worry, if you're not in the top 3, you can still earn a share of the prize pool! Each user will get 10 coins for each ticket they've collected, so keep collecting those!
                </div>
            </div>
            <div className='cashout-header-right' style={{marginBottom:'20px'}}>
                <div className='daily-rewards-code'>
                                <div style={{ texAling: 'left', fontSize: '12px', gap:'10px', display:'flex', flexDirection:'column' }}>
                                    {/* <div onClick={(e) => {
                                        navigator.clipboard.writeText(`https://paidcash.co/r/${data.referralCode}`);
                                    }} className="link">{data.referralCode == undefined ? <Skeleton style={{ width: '90px' }} /> : `https://paidcash.co/r/`}
                                    </div> */}
                                    Enter the tickets amount and click the entry button to enter the competition. The more tickets you have, the higher your chances of winning the prize pool!
                                     <div className='input-container' style={{gap:'0px', height:'40px'}}>
                                        <input onChange={(e) => {
                                         
                                           
                                        }} type='number' className="ticketsInput" name="coin_amount" defaultValue={data.referralCode} autoComplete="off" />
                  <button onClick={(e) => {
                    let dataTicket = document.querySelector('.ticketsInput').value
                    socket.emit('userAction', {
                        action: 'enterChristmas2024',
                        tickets:dataTicket
                    })
                                          setTimeout(()=>{    socket.emit('emitRankingDataChristmas2024')
                                              socket.emit('emitRankingDataDetailsChristmas2024')
                                              socket.emit('getChristmas2024', {})
                                            },1000)
                                        }} className="button button-brand">Enter</button>
                                    </div>
                                   
                                         {/* <button  onClick={(e) => {
                                            navigator.clipboard.writeText(`https://paidcash.co/r/${data.referralCode}`);
                                        }} className="button button-brand" style={{height:'30px', cursor:'pointer'}}>Copy</button> */}
                                    </div>
                                </div>
                                <div className='cashout-header-balance'>
                                    <div className='cashout-header-balance-amount'>
                                    <FaTicketAlt  className="ticket-design" style={{marginRight:'10px'}}/> 
                                        <div className="header-balance-text">{tickets==-1?<Skeleton className='offer-featured-box' style={{ width: '10px', height: '10px' }} />:numberWithCommas(tickets)}</div>
                                    </div>
                                    <div className='cashout-header-balance-title'>Current Tickets</div>
                                </div>
                              
                            </div>
            <div className='daily-rewards-header' >
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                   
                    {textTimer == '-1' ? <Skeleton style={{ width: '100px', height: '30px' }} /> : textTimer}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                      Time Remaining
                    </div>
                </div>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                   
                     {usersToday == -1 ? '-' : numberWithCommas(usersToday)} 
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                        users joined
                    </div>
                </div>
                <div className='daily-rewards-header-amount-container'>
                    <div className='daily-rewards-header-amount-container-title'> 
                    {userEarnedToday == -1 ? '-' : ` ${(userEarnedToday)}`}
                    </div>
                    <div className='daily-rewards-header-amount-container-description'> 
                       Entries
                    </div>
                </div>
            </div>
          
            <DataTable
                value={data} lazy dataKey="id" paginator
                first={lazyState.first} rows={50} totalRecords={50} onPage={onPage}
                onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder} alwaysShowPaginator={false}
                onFilter={onFilter} filters={lazyState.filters} loading={loading}
                selection={userEarnings} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                  <Column field="id" header="Place" body={(product) => {
                    return <div className="lb_place" style={{
                        background:
                        product.id < 5 ? 'rgba(255,184,0,.1)' :
                        product.id < 10 ? 'rgba(255,70,70,.1)' :
                        product.id < 20 ? 'rgba(45,129,255,0.1)' :
                        product.id < 30 ? 'rgba(167,255,255,0.1)' :
                        product.id < 50 ? 'rgba(255,70,70,0.1)' :
                        product.id < 100 ? 'rgb(30 190 115/20%)' : '',
                        color:
                        product.id < 5 ? '#fcce00' :
                        product.id < 10 ? '#ff4646' :
                        product.id < 20 ? '#2d81ff' :
                        product.id < 30 ? '#a7ffff' :
                        product.id < 50 ? '#ff4646' :
                        product.id < 100 ? '#1ebe73' : '',
                    }}>#{product.id + 1}</div>
                }}></Column>
                <Column field="username" header="User" body={(product) => {
                    return <div>{product.username != undefined ?<UserIDProfileModalContainerDTImg image={
                        `https://api.dicebear.com/9.x/fun-emoji/svg?seed=${product.username}`
                    }  name={product.username}  />: <Skeleton style={{ width: '70px' }} />}</div>
                }}></Column>
                 <Column field="earned" header="Entries" body={(product) => {
                    return <div>{product.earned != undefined ? <>
                     <FaTicketAlt  className="ticket-design" style={{marginRight:'10px'}}/> 
                        <div className="amount-leaderboard"> {numberWithCommas(product.earned)}</div></> : <Skeleton style={{ width: '70px' }} />}</div>
                }}></Column>
                <Column field="reward" header="Prize" body={(product) => {
                                    return   <div>{product.reward != undefined? product.reward==-1 ?'-': <>
                                        <ProgressiveImage
                                           preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                           src="/assets/img/faucetify_coin_small.png"
                                           render={(src, style) => <img className="leaderboard-reward-image" src={src} style={style} />}
                                       /> <div className="amount-leaderboard"> {numberWithCommas(product.reward)}</div></> : <Skeleton style={{ width: '70px' }} />}</div>
                                }}></Column>
                 </DataTable>
            
        </div>
    )
}